<template>
  <div class="viewContainer">
    <v-container
      class="pa-4 pa-sm-8"
    >
      <v-card class="content-tabs-wrapper pa-4 pa-sm-8" elevation="0">
        <v-skeleton-loader
          v-if="stats===null"
          type="card"
        />
        <div v-if="stats!==null">
          <v-responsive :aspect-ratio="2.4">
            <v-sparkline
              :labels="stats.userChartLabels"
              :value="stats.userChartValues"
              type="trend"
              :gradient="$vuetify.theme.dark ? gradientDark : gradientLight"
              gradientDirection="top"
              stroke-linecap="round"
              line-width="2"
              padding="16"
              height="100%"
              label-size="4"
              :color="$vuetify.theme.dark ? 'rgba(255, 255, 255, .7)' : 'rgba(0, 0, 0, 0.87)'"
              smooth
              auto-draw
            ></v-sparkline>
          </v-responsive>
          <v-divider/>
          <v-card-text class="px-0 pb-0 pt-4 pt-sm-8">
            <div class="d-flex align-end">
              <div>
                <h2 class="title">{{ $t('users') }} <span>({{ stats.total.users }})</span></h2>
                <span :class="{'red--text': stats.users.signupsChange < 0, 'green--text': stats.users.signupsChange > 0}">
                  <v-icon size="14" v-if="stats.users.signupsChange < 0" :class="{'red--text': stats.users.signupsChange < 0, 'green--text': stats.users.signupsChange > 0}">mdi-arrow-down</v-icon>
                  <v-icon size="14" v-if="stats.users.signupsChange > 0" :class="{'red--text': stats.users.signupsChange < 0, 'green--text': stats.users.signupsChange > 0}">mdi-arrow-up</v-icon>
                  {{ formatNumber(stats.users.signupsChange) }}
                </span>
                {{ $t('vs_past_7_days') }}
              </div>
              <v-btn rounded elevation="0" :to="{ name: 'admin.users' }" class="ml-4">{{ $t('more') }} <v-icon size="15" class="ml-1">mdi-arrow-right</v-icon></v-btn>
            </div>
          </v-card-text>
        </div>
      </v-card>

      <v-card class="content-tabs-wrapper pa-4 pa-sm-8 mt-4 mt-sm-8" elevation="0">
        <div v-if="stats===null">
          <v-skeleton-loader
            type="sentences"
            max-width="150"
          />
        </div>
        <div v-if="stats!==null">
          <h2 class="title">{{ $t('version') }} {{ stats.version }}</h2>
          {{ $t('version_info') }}
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
// import MenuAdmin from '@/components/ui/MenuAdmin.vue'
export default {
  // components: {
  //   MenuAdmin
  // },
  data: () => ({
    stats: null,
    locale: 'th',
    tab: 'tab-0',
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: [],
    loading: true,
    business: null,
    search: '',
    gradientLight: ['#000000', '#232425'],
    gradientDark: ['#ffffff', '#232425'],
  }),
  created () {
    // Set locale
    this.locale = 'th'
    this.moment.locale(this.locale.substr(0,2))

    // Load dashboard stats
    this.loadStats()
  },
  methods: {
    loadStats () {
      this.axios
        .get('/admin/stats', { params: { locale: this.$i18n.locale }})
        .then(response => {
          let stats = response.data
          this.stats = stats

          let userChartLabels = this.$_.map(stats.users.signupsCurrentPeriod, (count, date) => {
            return this.moment(date).format('D')
          })

          let userChartValues = this.$_.map(stats.users.signupsCurrentPeriod, (count, date) => {
            return count
          })

          this.stats.userChartLabels = userChartLabels
          this.stats.userChartValues = userChartValues

          this.overlay = false
          this.loading = false
        })
    },
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    formatCurrency (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: this.business.currency}).format(number)
    },
    formatDate (dt, format) {
      if (dt === null) {
        dt = '-'
      } else {
        dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
      }
      return dt
    },
  }
};
</script>
